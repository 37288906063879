<template>
    <div class="content_btn_csv">
    
           <button  v-if="downloadText =='Descargar Reporte.CSV'"
         class="downloadbtn "
         @click="download(filename, reporte)">
            {{downloadText}} <img v-if="!isDownloaded" :src="icon" class="icon mb-1 mr-1" /><img v-else :src="check" class="icon mb-1 mr-1" /> 
    
          </button> 
          <button v-else 
         class="downloadbtn "
         @click="download(filename, reporte )">
            {{downloadText}} <img v-if="!isDownloaded" :src="icon" class="icon mb-1 mr-1" /><img v-else :src="check" class="icon mb-1 mr-1" /> 
    
          </button>  
          
    </div>
       
    </template>
    
    <script>
    import download_icon from '../assets/icono_descargar.png';
    import check from '../assets/icono_check.png';
    
    export default {
        name: 'CSV',
        props: {
            reporte:{
                required: true
            },
          
        },
        data() {
            return {
                icon: download_icon, 
                filename: 'REPORTE.csv',
                check: check, 
    
            }
        },
        computed: {
            isDownloaded() {
                return this.$store.getters['admin/downloadFlag'];
            },
            downloadText() {
                return this.$store.getters['admin/downloadText'];
            },
        },
        methods:{
            download: function(filename, rows) { 
               
                this.$store.commit('admin/DOWNLOADING', 'Descargando...');                
             
                let newArr =[]

                newArr.push([ 'EMAIL',  'FECHA'])
                    
                rows.forEach(item => {
                 
                    let tempArr = [];

                    if(item.email.includes('#')){
                        item.email = item.email.replace(/#/g, " ");
                    }
                    tempArr.push(item.email);
                    tempArr.push(item.created_at);

                    newArr.push(tempArr);
                });
                

                let csvFile = '';
                let arrlen = newArr.length;
                for (let i = 0; i < arrlen; i++) {        
                    csvFile += this.processRow(newArr[i]);
                }

                csvFile = 'data:text/csv;charset=utf-8,' +csvFile
              
              
                this.blob(filename, csvFile)
            },
            processRow: function (row) {
                let finalValue = '';
                let rowlen = row.length;
    
                for (let j = 0; j < rowlen; j++) {
                    let innerValue = row[j] === null ? '' : row[j].toString();
                    let result = innerValue.replace(/"/g, '""');
                    if (result.search(/("|,|\n)/g) >= 0){ result = '"' + result + '"'; }
                    if (j > 0){ finalValue += ',';}
    
                    finalValue += result;
                }
                return finalValue + '\n';
            },
            blob: function (filename, csvFile){
               // let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) {
                        var encodedUri = encodeURI(csvFile);
                        //let url = window.URL.createObjectURL(csvFile);
                        link.setAttribute("href", encodedUri);
                        link.setAttribute("download", filename);
                        link.click();
                    }
                }
                this.$store.commit('admin/DOWNLOAD_SUCCESS', 'Archivo descargado'); 
                setTimeout(() => {
                    this.$store.commit('admin/DOWNLOADING', 'Descargar Reporte.CSV');     
                }, 1000);
            }
        }
    }
    </script>
    <style scoped>
    .content_btn_csv{

        display: flex;
    justify-content: left;
    width: 25%;
    }
    button.downloadbtn {
        display: flex;
        align-items: center;
        background: var(--color-1 );
        color: white;
        font-weight: 600;
        border: none;
        width: auto;    
        border-radius: 5vw;
        padding: .5vw 1vw;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 300ms;
        height: 2.5vw;
        margin-top: 0.7291666666666666vw;
        margin-left: 1vw;

    }
    button.downloadbtn:hover{
      background: #3db34d;

    }
    button.downloadbtn img {
        width: .6vw;
        margin-left: 1vw;
    }
    </style>
    
    