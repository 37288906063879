<template>
  <div id="Dashboard">
    <SideMenu />
    <div class="content_sections">
      <Sections :option="'Default'" />
    </div>
  </div>
</template>
<script>
import SideMenu from "../components/SideMenu.vue";
import Sections from "../components/Sections.vue";
export default {
  name: "Dashboard",
  components: {
    SideMenu,
    Sections,
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Administrador - Revista Foodie";
      },
    },
  },
};
</script>
<style scoped>
#Dashboard {
  display: flex;
  font-size:0.982vw;
    font-weight: 400;
  margin: 0;
    line-height: 0.914vw!important;
}

.content_sections {
  background: var(--color-5);
  min-height: 59.25vw;
  width: 78.17708333333333vw;
}
</style>
