<template >
  <div id="EditBnr">
    <div class="form-content">
      <form @submit.prevent="submit(added.id,added.link,added.section, added.image, added.image_mv,file1,file2)">
        <div class="grup-form dflx">
          <div class="boxform">
            <label for="name" class="name">Link </label>
            <input
              v-model="added.link"
              type="text"
              id="name"
              name="name"
              class="in1"
            />
          </div>

          <div class="boxform">
            <label>Imagen PC</label>
            <div class="dflx">
              <label class="filelabel" for="uploadimg">
                <span>Seleccionar Archivo</span></label
              >
              <label class="filelabelname tbl_item" for="uploadimg">
                {{ archivo == 'No se eligió archivo' ? added.image : archivo }}</label
              >
            </div>

            <input
              @change="previewFiles"
              type="file"
              id="uploadimg"
              name="uploadimg"
              class="fileinput"
            />
          </div>

          <div class="boxform">
            <label>Imagen Movil</label>
            <div class="dflx">
              <label class="filelabel" for="uploadimg2">
                <span>Seleccionar Archivo</span></label
              >
              <label class="filelabelname tbl_item" for="uploadimg2">
                {{ archivo2 == 'No se eligió archivo' ? added.image_mv : archivo2 }}
                </label
              >
            </div>

            <input
              @change="previewFiles2"
              type="file"
              id="uploadimg2"
              name="uploadimg2"
              class="fileinput"
            />
          </div>
        </div>
        <div class="dflx">
          <p @click="wait()" class="btnRegresar">Regresar</p>
          <button class="alta" type="submit">Editar</button>
        </div>

        <div v-if="status != ''" class="status_messages">
          <div v-if="status == 'success'" class="msg msg_success">
            <p>{{ message }}</p>
          </div>
          <div v-if="status == 'error'" class="msg msg_error">
            <p>{{ message }}</p>
          </div>
        </div>
      </form>
    </div>

    <div v-if="viewModal" class="modal_view">
      <div id="modal_edit">
        <div class="body dflx">
          <h3>Banner actualizado</h3>
          <img src="../../assets/add.png" alt="icono alerta" />
        </div>

        <div class="modal_edit_btns dflx">
          <p @click="wait()" class="otro">Aceptar</p>
          <!-- <p  @click="back()" class="inicio">Inicio</p>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "EditBnr",

  data() {
    return {
      status: "",
      message: "",
      active: false,
      value1:"",
      viewModal: false,
      archivo: "No se eligió archivo",
      file1: null,
      archivo2: "No se eligió archivo",
      file2: null,
    };
  },
  created() {
        let payload={
            id:this.added.id,
            option: "banner",
        }
          this.getInfoByIdBnr(payload);
  },
  computed: {
    added() {
      return this.$store.getters["banner/getAdded"];
    },
    Banner() {
      return this.$store.getters["banner/data"];
    },
  },
  methods: {
    ...mapActions("banner", ["getInfoByIdBnr"]),
    ...mapActions("banner", ["setAddedBnr"]),
    ...mapActions("banner", ["setHistoryOptionBnr"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    ...mapActions("banner", ["getInfoByIdBnr"]),
    submit: async function (id, link, section, image, image_mv, f1, f2) {
      this.status = "";
      this.message = "";
      let data = [];
      let img1;
      let img2;

        if(f1 == null ){
          img1 = image
        }else{
          img1 = f1
        }
        if(f2 == null ){
          img2 = image_mv
        }else{
          img2 = f2
        }
         data = new FormData();
        data.append("id", id);
        data.append("link", link);
        data.append("section", section);
        data.append("image", img1);
        data.append("image_mv", img2);
        data.append("_method", "PUT");
        
      let result = await this.$store.dispatch("banner/editItemBnr", {
        option: "banner",
        item: data,
      });
      if (result.status == "error") {
        this.status = "error";
        this.message = result.message;
      } else {
        // success
        /*this.setAddedBnr(result.added);*/
        this.showModal();
      }
    },
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },

    includesItem(search, name) {
      if (search == "") {
        return true;
      }
      name = name.toLowerCase();
      search = search.toLowerCase();
      if (search == "") {
        return false;
      }
      return name.includes(search);
    },
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 2000);
    },
    delMsgs: function () {
      this.status = "";
      this.message = "";
      this.status2 = "";
      this.message2 = "";
    },

    showModal: function () {
      this.viewModal = true;
    },
    closeModal: function () {
      this.viewModal = false;
      this.setAddedBnr("");
      this.setHistoryOptionBnr("Default");
    },
    Edit: function () {
      this.viewModal = false;
    },
    back: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    previewFiles(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file1 = files[0];
      this.archivo = this.file1.name;
    },
    previewFiles2(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file2 = files[0];
      this.archivo2 = this.file2.name;
    },
  },
};
</script>
<style scoped>
#EditBnr label {
  color: var(--color-2);
  font-weight: 700;
  font-size: 0.7291666666666666vw;
  margin-bottom: 0.3vw;
}

#EditBnr input,
#EditBnr select {
  height: 2.5vw;
  width: 14.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
}

#select-role {
  height: 2.5vw;
  width: 13.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
  border-left: 0.052083vw solid var(--color-2);
  border-right: 0.052083vw solid var(--color-2);
  border-top: 0.052083vw solid var(--color-2);
  border-bottom: 0;
}

</style>