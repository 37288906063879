<template>
  <div id="SideMenu" class="dflx">
    <div class="box-1">
      <div class="logo">
      
         <img v-if="validar()"  :src="srcImg(logo.logo)" alt="">  
      </div>
      <div class="msg-user">
        <p>
          ¡HOLA, <span>{{ identity.name | splitUp }}!</span>
        </p>
      </div>
      <div class="menu">
        <ul>
          <li
            :class="{ active: navOption == 'Dashboard' }"
            @click="activeMenu(0), miPagina()"
          >
            <div class="text-menu">MI <span> PÁGINA</span></div>
            <div v-if="navOption == 'Mi Página'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>
          <li
            :class="{ active: navOption == 'Personalizacion' }"
            @click="
              activeMenu(0),
                setOption('Personalizacion'),
                setHistoryOptionPrf('Default')
            "
          >
            <div class="text-menu"><span>PERSONALIZACIÓN</span></div>
            <div v-if="navOption == 'Personalizacion'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'Banner' }"
            @click="
              activeMenu(0), setOption('Banner'), setHistoryOptionBnr('Default')
            "
          >
            <div class="text-menu"><span>BANNER</span></div>
            <div v-if="navOption == 'Banner'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'Slide' }"
            @click="
              activeMenu(0), setOption('Slide'), setHistoryOptionSld('Default')
            "
          >
            <div class="text-menu"><span>SLIDES</span></div>
            <div v-if="navOption == 'Slide'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'Usuarios' }"
            @click="
              activeMenu(0),
                setOption('Usuarios'),
                setHistoryOptionUsr('Default')
            "
          >
            <div class="text-menu"><span>USUARIOS</span></div>
            <div v-if="navOption == 'Usuarios'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'MenuB' }"
            @click="activeMenu(1), setOption('MenuB')"
          >
            <div class="text-menu">MENÚ <span> BLOG</span></div>
            <div
              v-if="navOption == 'MenuB' || hidemenu"
              class="icon-active-menu"
            >
              <div class="ico-2"></div>
            </div>
          </li>
          <li
            class="submenu"
            :class="{ active: navOption == 'BLOG' }"
            v-if="hidemenu"
            @click="setOption('BLOG'), setHistoryOptionBlo('Default')"
          >
            &#10625; BLOG
          </li>
          <li
            
            :class="{ active: navOption == 'BLOGCAT' }"
            @click="
              activeMenu(0),setOption('BLOGCAT'), setHistoryOptionCat('Default')"
          >
            <div class="text-menu"><span>CATEGORÍA</span></div>
            <div v-if="navOption == 'BLOGCAT'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li
            :class="{ active: navOption == 'colaboradores' }"
            @click="
              activeMenu(0),
                setOption('colaboradores'),
                setHistoryOptionClb('Default')
            "
          >
            <div class="text-menu"><span>COLABORADORES</span></div>
            <div v-if="navOption == 'colaboradores'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>
          <li
            :class="{ active: navOption == 'TAG' }"
            @click="
              activeMenu(0), setOption('TAG'), setHistoryOptionTg('Default')
            "
          >
            <div class="text-menu"><span>ETIQUETAS</span></div>
            <div v-if="navOption == 'TAG'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>

          <li :class="{ active: navOption == 'suscripciones' }" @click=" activeMenu(0), setOption('suscripciones'), setHistoryOptionSbs('Default')
            ">
            <div class="text-menu"><span>NEWSLETTER</span></div>
            <div v-if="navOption == 'suscripciones'" class="icon-active">
              <div class="ico"></div>
            </div>
          </li>


        </ul>
      </div>
    </div>
    <div class="box-2">
      <div class="triangulo"></div>
    </div>
  </div>
</template>
<script>

let urlpath = require('../global/url')
import { mapActions } from "vuex";
export default {
  name: "SideMenu",
  data() {
    return {
      navOption: "Default",
      hidemenu: false,
      hidemenu2: false,
      hidemenu3: false,
         urlpath:urlpath,
                statusimg:false,
    };
  },
 async created() {
 await this.getIdentity();
    let nav = localStorage.getItem("nav");

    if (nav) {
      this.setHistoryOptionMain(nav);
      this.navOption = nav;

      if (nav == "BLOG" || nav == "BLOGCAT") {
        this.hidemenu = true;
      }

      /*if (nav == "PROJ" || nav == "PROJCAT") {
        this.hidemenu2 = true;
      }

      if (nav == "videos" || nav == "VDOCAT") {
        this.hidemenu3 = true;
      }*/
    }

       await this.getAllInfoLgo('personalize')

     const result = await this.status_img(this.logo.logo)  
 


  },
  computed: {
    identity() {
      return this.$store.getters["admin/getIdentity"];
    },
       logo() {
         const arr = this.$store.getters["logo/data"];
         
         if(Array.isArray(arr)){

         

           return arr[0]
         }else{
           return {}
         }
      

        },
  },
  methods: {
    ...mapActions("main", ["setHistoryOptionMain"]),
    ...mapActions("blog", ["setHistoryOptionBlo"]),
    ...mapActions("blog_category", ["setHistoryOptionBct"]),
    ...mapActions("usuarios", ["setHistoryOptionUsr"]),
    ...mapActions("tag", ["setHistoryOptionTg"]),

    ...mapActions("blog_category", ["getAllInfoBct"]),
    ...mapActions("blog", ["getAllInfoBlo"]),
    ...mapActions("tag", ["getAllInfoTg"]),
    ...mapActions("usuarios", ["getAllInfoUsr"]),
   ...mapActions('logo', ['getAllInfoLgo']),
    ...mapActions("slide", ["setHistoryOptionSld"]),
     ...mapActions("slide", ["getAllInfoSld"]),

    ...mapActions("banner", ["setHistoryOptionBnr"]),
     ...mapActions("banner", ["getAllInfoBnr"]),

     ...mapActions("profile", ["setHistoryOptionPrf"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    ...mapActions("colaboradores", ["setHistoryOptionClb"]),
    ...mapActions("colaboradores", ["getAllInfoClb"]),
    ...mapActions("category", ["setHistoryOptionCat"]),
    ...mapActions("category", ["getAllInfoCat"]),
    ...mapActions("suscripciones", ["setHistoryOptionSbs"]),
    ...mapActions("suscripciones", ["getAllInfoSbs"]),

    activeMenu(value) {
      if (value == 1) {
        this.hidemenu ? (this.hidemenu = false) : (this.hidemenu = true);
      } else if (value == 2) {
        this.hidemenu2 ? (this.hidemenu2 = false) : (this.hidemenu2 = true);
      } else if (value == 3) {
        this.hidemenu3 ? (this.hidemenu3 = false) : (this.hidemenu3 = true);
      } else {
        this.hidemenu = false;
        this.hidemenu2 = false;
        this.hidemenu3 = false;
      }
    },
    getIdentity: async function () {
      let response = await this.$store.dispatch("admin/getData");
      return response;
    },
    validar(){
      return this.logo?.logo
    },
    setOption: function (option) {
      if (option == "MenuB" || option == "MenuP" || option == "MenuV") {
        this.navOption = option;
      } else {
        this.navOption = option;
        localStorage.setItem("nav", option);
        this.setHistoryOptionMain(option);

        //getAll info
        if (option == "Usuarios") {
          this.getAllInfoUsr("usuarios");
        } else if (option == "BLOG") {
          this.getAllInfoBlo("blog");
        } else if (option == "BLOGCAT") {
          this.getAllInfoCat("category");
        } else if (option == "TAG") {
          this.getAllInfoTg("tag");
        } else if (option == "colaboradores") {
          this.getAllInfoClb("colaboradores");
        }else if(option == 'Slide'){
              this.getAllInfoSld("slide");
        }else if(option == 'Banner'){
              this.getAllInfoBnr("banner");
        }else if(option =='Personalizacion'){
              this.getAllInfoPrf("profile");
        }else if(option =='suscripciones'){
              this.getAllInfoSbs("suscriptores");
        }
      }
    },
    wait: function () {
      setTimeout(() => this.$router.go(), 0);
    },

    miPagina() {
      window.open("https://revistafoodie.com/", "_blank");
    },


   status_img:async  function (img){
      this.statusimg =false;
                 let payload ={
                    option:'personalize',
                    image:img
                }
                let response  =''

                try{
                     response =  await this.$store.dispatch("main/getStatusImage", payload);
                  
                      if(!response?.data?.status){
                       this.statusimg =true
                      }else{
                        response = ''
                      }
                }catch(err){
                    console.log(err)
                }

                return response
               
    },

       srcImg:   function (img){
                let src = `${urlpath.url()}/c15_BjM/UQ7-CoE/personalize-img/${img}`
                
    
                return src
        }, 





  },
  filters: {
    splitUp: (value) => {
      if (!value) return "";
      value = value.toString();

      let splitStr = value.toUpperCase().split(" ");

      return splitStr[0].charAt(0) + splitStr[0].substring(1);
    },
  },
};
</script>
<style scoped>
#SideMenu {
  min-height: 59.25vw;
}

.box-1 {
  width: 16.614583333333332vw;
  background: var(--color-2);
}
.logo {
  margin-top: 4.166666666666666vw;
  margin-left: 2.03125vw;
}
.logo img {
  width: 12.604166666666666vw;
  height: auto;
}
.msg-user {
  margin-left: 2.03125vw;
  margin-top: 0.78125vw;
  width: 12.552083333333334vw;
  height: 2.03125vw;
  background: var(--color-5);
  border-radius: 1vw;
}
.msg-user p {
  color: var(--color-1);
  text-align: center;
  height: 2.03125vw;
  padding-top: 0.5vw;
  font-weight: 500;
  margin: 0px;
  font-size: 0.625vw;
}
.msg-user p span {
  font-weight: 800;
}

.box-2 {
  width: 4.21875vw;
}
.triangulo {
  width: 0;
  height: 0;
  border-right: 4.21875vw solid transparent;
  border-top: 4.21875vw solid var(--color-2);
}

.menu {
  margin-top: 5.677083333333333vw;
}

li.submenu {
  border: none !important;
}
.menu ul {
  margin-left: 2.03125vw;
  padding: 0vw;
}
.menu ul li {
  height: 1.6vw;
  width: 11.352083vw;
  border: 1px solid var(--color-5);
  color: var(--color-5);
  border-radius: 1.2vw;
  padding-left: 1vw;
  padding-top: 0.5vw;
  padding-bottom: 0.2vw;
  margin-bottom: 0.6020833333333334vw;
  cursor: pointer;
  display: flex;
  font-size: 0.7291666666666666vw;
}
.menu ul li span {
  font-weight: 700;
}

.text-menu {
  width: 9.5vw;
  padding-top: 0.1vw;
}

.active {
  background: var(--color-6);
}

li.active {
  border: 0px !important;
}
.icon-active,
.icon-active-menu {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  background: var(--color-5);
  border-radius: 1vw;
}
.ico {
  width: 0.6515624999999999vw;
  height: 0.6515624999999999vw;
  background: var(--color-2);
  border-radius: 1vw;
  margin-top: 0.33vw;
  margin-left: 0.34vw;
}
.ico-2 {
  margin-top: 0.55vw;
  margin-left: 0.3vw;
  width: 0;
  height: 0;
  border-right: 0.364583vw solid transparent;
  border-top: 0.364583vw solid var(--color-2);
  border-left: 0.364583vw solid transparent;
  border-bottom: 0.364583vw solid transparent;
}
@media (min-width:768px) {
}
</style>
